<template>
	<v-app>
		<v-app-bar app color="white" elevation="1">
			<v-img
				class="shrink mr-2"
				contain
				src="../images/EMSD.jpg"
				transition="scale-transition"
				width="30%"
				max-width="200"
			/>
			<div class="header-title">
				e-Platform for periodic examination photos submission
			</div>
		</v-app-bar>
		<v-main>
			<v-container>
				<v-col>
					<v-layout v-if="isLogin()" justify-center
						>{{ getSessionTime()}}</v-layout
					>
					<v-layout v-if="isLogin()" justify-end
						><v-btn right color="primary" @click="logout()" dark
							>登出 Logout</v-btn
						></v-layout
					>
					<router-view v-on:snack-bar="promptSnackbar" />
				</v-col>
			</v-container>
		</v-main>
		<v-snackbar v-model="snackbar" :color="snackbar_color" outlined top>
			{{ snackbar_msg }}
			<template v-slot:action="{ attrs }">
				<v-btn color="blue" text v-bind="attrs" @click="snackbar = false"
					>關閉 Close</v-btn
				>
			</template>
		</v-snackbar>
		<v-footer fixed app color="white">
			<v-col cols="12" class="footer-font">
				<v-row>
					<span> <strong>Version: </strong> {{ 'v1.0.0' }} </span>
				</v-row>
				<v-row>
					<span>
						<strong>Last Revision Date: </strong>
						{{ '2021/9/16' }}
					</span>
				</v-row>
			</v-col>
		</v-footer>
	</v-app>
</template>

<style lang="scss">
@import 'https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css';
</style>

<script>
import LoginService from './service/login-service';
const loginService = new LoginService();
const moment = require('moment-timezone');

export default {
	name: 'App',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMSD e-Platform for periodic examination photos submission',
	},
	data: () => ({
		snackbar: false,
		snackbar_color: '',
		snackbar_msg: '',
	}),
	methods: {
		getSessionTime(){
			if (!loginService.isLogin()){
				return '';
			}else{
				if (loginService.getExpiry()){
					let momentDate = moment(loginService.getExpiry());
					let chiDateStr = momentDate.format('YYYY年MM月DD日 HH:mm:ss');
					let engDateStr = momentDate.format('DD/MM/YYYY HH:mm:ss ');
					return `此次登入將於 ${chiDateStr} 失效。 The session will expire at ${engDateStr}.`;
				}else{
					return '';
				}
			}
		},
		promptSnackbar(e) {
			this.snackbar = true;
			this.snackbar_color = e.color;
			this.snackbar_msg = e.msg;
		},
		isLogin() {
			return loginService.isLogin();
		},
		logout() {
			// console.log('logout clicked');
			loginService.logout();
			this.$router.replace({ path: '/login' });
		},
	},
};
</script>

<style lang="scss">
.footer-font {
	font-size: small;
}
</style>
